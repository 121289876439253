const ROOT = '/';

const LOGIN_ROUTE = `/login`;
const DASHBOARD_ROUTE = `/dashboard`;
const PROFILE_ROUTE = '/profile';
const CHANGE_PASSWORD_ROUTE = '/change-password';

const STATIC_PAGE_ROUTE = {
    ROOT:'/static-pages',
    ADD:'/static-pages/add',
    EDIT:'/static-pages/edit/:staticPageId',
    VIEW:'/static-pages/view/:staticPageId'
}

const TASK_TYPE_ROUTE = {
    ROOT:'/task-types'
}

const USER_ROUTE = {
    ROOT:'/user',
}

const ERROR_ROUTE = {
    404:'*',
}

export const LOCATIONS = Object.freeze({
    ROOT,
    DASHBOARD_ROUTE,
    LOGIN_ROUTE,
    PROFILE_ROUTE,
    STATIC_PAGE_ROUTE,
    USER_ROUTE,
    CHANGE_PASSWORD_ROUTE,
    TASK_TYPE_ROUTE,
    ERROR_ROUTE
});